import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import LogoIcon from '../svg/LogoImage';
import SvgCharts from '../svg/SvgCharts';

const Index = () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            Welcome to exilium
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
          Since its inception, Exilium has served as a highly decentralized financial Infrastructure.
          </p>
          <p className="text-base lg:text-xl mt-6 font-light">
          Inspired by the Binance vision we have committed to the ideals of decentralized permissionless access, security and immutability.
          </p>
          <p className="mt-8 md:mt-12">
            <Button size="lg">Invest Now</Button>
          </p>
          <p className="mt-4 text-gray-600">Sed fermentum felis ut cursu</p>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
      </div>
    </section>
    <SplitSection
      id="roadmap"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">Trade</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Instantly swap crypto tokens: no registration or account needed.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
          The people's exchange
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Simple and efficient, Exilium is a decentralized exchange on Binance Smart Chain.<br/>
          Since its inception Exilium has served as a highly decentralized financial infrastructure. Inspired by the Binance vision, our platform is committed to the decentralized accessibility of financial services around the world without fear of being discriminated against or counterparty risk.<br/>
          Exilium enshrines its platform as a publicly owned and self-sustained investment infrastructure while carefully protecting its autonomous qualities for its community.<br/><br/>
          Committed to change and giving back to the world for all to benefit, Exilium has a strong emphasis on charity and raising fund for charitable causes. We believe Crypto can be used for good and our vision is to show it to the world. 
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
          Low fees
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Why pay more? Exilium runs on Binance Smart Chain, a blockchain with much lower transaction costs than Ethereum or Bitcoin. Trading fees are also kept lower than other top decentralized exchanges, so that's a double whammy for you!
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
          Decentralized
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
          Trade directly from your wallet app. Unlike centralized exchanges like Binance or Coinbase, Exilium does not hold your funds when you trade: <b>therefore, you retain 100% ownership of your own crypto.</b>
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Placeholder</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="+100%" secondaryText="Stats Information" />
          </div>
        </div>
      </div>
    </section>
    <section className="container mx-auto my-20 py-24 bg-gray-200 rounded-lg text-center">
      <h3 className="text-5xl font-semibold">Placeholder</h3>
      <p className="mt-8 text-xl font-light">
        Quis lectus nulla at volutpat diam ut. Enim lobortis scelerisque fermentum dui faucibus in.
      </p>
      <p className="mt-8">
        <Button size="xl">Get Started Now</Button>
      </p>
    </section>
  </Layout>
);

export default Index;
